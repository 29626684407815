<template>
  <div class="">
    <navbar title="投票"></navbar>
    <div class="voteD-box">
      <!-- 每一个项 -->
      <div class="voteD-each" v-for="(item, index) in voteDetail" :key="index">
        <div class="vd-head">
          <img
            class="vd-head-img"
            :src="
              item.filename
                ? WX_BASE_URL + item.relativePath + '/' + item.filename
                : require('@/assets/img/default-img.png')
            "
            alt=""
          />
          <!-- 姓名和政治面貌 -->
          <div class="vd-political">
            <div class="vd-p-name">{{ item.name }}</div>
            <div class="vd-p-political">{{ item.post }}</div>
          </div>
        </div>
        <div v-if="isVoted == false" @click="vote(item.id)" class="vd-foot">
          给TA投票
        </div>
        <div v-if="isVoted == true && voteDetaiId == item.id" class="vd-foot">
          已投给TA
        </div>
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import tabbar from "@/components/tabBar.vue";
import navbar from "@/components/navBar.vue";
import { selectVoteDetalList, userVote } from "@/api/api.js";
import { Toast, Dialog } from "vant";
export default {
  name: "Home",
  components: {
    tabbar,
    navbar,
  },
  data() {
    return {
      voteDetail: [],
      // 是否已经投票
      isVoted: false,
      // 被投票人的ID
      voteDetaiId: "",
    };
  },
  mounted() {
    // 查询投票栏目
    this.selectVote();
  },
  methods: {
    // 查询投票栏目
    selectVote() {
      selectVoteDetalList({
        openId: localStorage.getItem("openId"),
        voteId: this.$route.query.voteId,
      }).then((res) => {
        if (res.data.code == 44) {
          Toast(res.data.msg);
        }
        if (
          res.data.data.voteDetaiId != null &&
          res.data.data.voteDetaiId != "" &&
          res.data.data.voteDetaiId != undefined
        ) {
          this.isVoted = true;
          this.voteDetaiId = res.data.data.voteDetaiId;
        }
        this.voteDetail = res.data.data.voteDetail;
      });
    },
    // 投票接口
    vote(id) {
      Dialog.confirm({
        message: "是否确定投票？",
      }).then(() => {
        // on confirm
        // TODO 这个地方需要添加openID的教研
        if (localStorage.getItem("nickname") == undefined || localStorage.getItem("nickname") == null
          || localStorage.getItem("headImgUrl") == undefined || localStorage.getItem("headImgUrl") == null
          || localStorage.getItem("openId") == undefined || localStorage.getItem("openId") == null || localStorage.getItem("openId") == "undefined") {
          Toast('未获取到微信头像或昵称，请重新登录')
          return
        }
        userVote({
          openId: localStorage.getItem("openId"),
          voteDetaiId: id,
          voteId: this.$route.query.voteId,
          wxAvatarUrl: localStorage.getItem("headImgUrl"),
          wxName: localStorage.getItem("nickname"),
        }).then((res) => {
          if (res.data.code == 0) {
            Toast("投票成功！");
            setTimeout(this.$router.push({ path: "/" }), 1000);
          } else {
            Toast(res.data.msg);
          }

        });
      });
    },
  },
};
</script>)
<style scoped>
.voteD-box {
  width: 100%;
  margin-bottom: 52px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.voteD-each {
  position: relative;
  width: 230px;
  height: 350px;
  flex-direction: column;
  margin-left: 1.8%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.vd-head {
  position: relative;
  width: 230px;
  height: 270px;
}

.vd-head-img {
  position: absolute;
  width: 230px;
  height: 270px;
  top: 0;
}

.vd-political {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #000000;
  opacity: 0.5;
  line-height: 60px;
}

.vd-p-name {
  float: left;
  margin-left: 15px;
  font-size: 28px;
  font-family: Songti SC;
  font-weight: bold;
  color: #ffffff;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vd-p-political {
  float: right;
  margin-right: 15px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vd-foot {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-top: 20px;
  background: #cca766;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  border-radius: 6px;
}
</style>
